<!--
 * @Author: your name
 * @Date: 2021-04-21 14:37:05
 * @LastEditTime: 2021-05-28 16:50:23
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one\src\views\dingyue\index.vue
-->
<template>
  <div class="allmsgclass">
    <navigation :stylemsg="stylemsg" msg="订阅设置">
      <!-- <div @click="$router.push('/dingyuesetup')" class="addding">
        <van-icon color="#FF5F19" size=".38rem" name="add-o" />
        <div>新增订阅</div>
      </div> -->
    </navigation>
    <div>
      <div class="daohang">
        <div class="sh1">
          <div @click="clickmenu(0)" :class="menuindex == 0 ? 'sem' : ''">
            全部
          </div>
          <div @click="clickmenu(2)" :class="menuindex == 2 ? 'sem' : ''">
            开启
          </div>
          <div @click="clickmenu(1)" :class="menuindex == 1 ? 'sem' : ''">
            关闭
          </div>
        </div>
        <!-- <div @click="filtermeth" class="sh2">
          筛选<img src="../../assets/sanjiao.png" />
          <div v-show="selenum != 0" class="tiaojian">{{ selenum }}</div>
        </div> -->
      </div>
      <div
        @click="todingyuemsg(item)"
        v-for="(item, index) in listmsg"
        v-bind:key="index"
        class="dyc"
      >
        <div>订阅主题</div>
        <div>
          <div>{{ item.theme ? item.theme : item.jop }}</div>
          <div
            :style="
              item.status == 1
                ? 'background-color:#088ffa'
                : 'background-color:rgb(255, 95, 25)'
            "
            class="menu2"
          >
            {{ item.status == 1 ? "开启" : item.status == 2 ? "关闭" : "" }}
          </div>
        </div>
        <div>
          <div></div>
          <van-icon size=".3rem" name="arrow" />
        </div>
        <div>
          {{ item.industry }}
          {{
            item.area == ""
              ? ""
              : (item.area + "").split("/")[0] +
                "/" +
                (item.area + "").split("/")[1]
          }}
          {{ item.education_background }}
          {{
            !item.years_working
              ? ""
              : item.years_working == "0-0"
              ? ""
              : item.years_working.split("-")[0] == "0"
              ? "应届"
              : item.years_working.split("-")[0]
          }}{{
            !item.years_working
              ? ""
              : item.years_working == "0-0"
              ? "应届"
              : item.years_working.split("-")[1] == "0"
              ? "-应届"
              : "-" + item.years_working.split("-")[1]
          }}
          {{ item.salary }}
        </div>
        <div>{{ item.add_time }}</div>
      </div>
    </div>
    <loading :isCustomize="true" @loaddom="loaddom" ref="loades">
      <template>
        <div>
          <div class="thetemclass">
            <img class="nomsgclassimg" src="../../assets/ding.png" alt="" />
          </div>
          <div class="zpclass">离心仪的人才就差一步啦~</div>
          <div class="boclass3 boclass2">
            <div>
              <div>1.点击底部【新增岗位订阅】；</div>
              <div>2.管理岗位开/关状态，每天坐收简历！</div>
            </div>
          </div>
        </div>
      </template>
    </loading>
    <div class="nomsgclass"></div>
    <filtermenu @shaimeth="shaimeth" ref="filterid"></filtermenu>
    <div class="fenxiang">
      <button @click="addmeth">
        <img src="../../assets/ad.png" alt="" /> 新增岗位订阅
      </button>
    </div>
  </div>
</template>
<script>
import loading from "../../components/loading2/index";
import filtermenu from "../../components/filtermenu/index";
export default {
  components: {
    loading,
    filtermenu,
  },
  data() {
    return {
      menuindex: 0,
      pageno: 0,
      listmsg: "",
      selenum: 0,
      stylemsg: "background-color: #f6f6f7;",
      listmsg2:[],
      openlength:""
    };
  },
  methods: {
    //新增岗位
    addmeth(){
      if(this.openlength>=5){
        this.$toast("最多只能开启5个岗位");
      }else{
        this.$router.push('/dingyuesetup')
      }
    },
    filtermeth() {
      this.$refs.filterid.showfilter();
    },
    shaimeth(msg) {
      let a = 0;
      msg.forEach((e) => {
        if (e != "") {
          a++;
        }
      });
      this.selenum = a;
    },

    todingyuemsg(item) {
      item.type = item.status == "2" ? "1" : "2";
      this.$router.push({
        path: "/dingyuemsg",
        query: {
          msg: JSON.stringify(item),
        },
      });
    },
    loaddom() {
      this.clickmenu(this.menuindex, "false");
    },
    clickmenu(index) {
      if (arguments[1] != "false") {
        this.menuindex = index;
        this.pageno = 0;
        this.$refs.loades.empty();
        this.listmsg = [];
      }
      let type;
      if (index == 0) {
        type = "";
      } else if (index == 1) {
        type = "2";
      } else {
        type = "1";
      }
      this.getmsg("/firm/v1/Resume/subscribeLists", {
        pageno: ++this.pageno,
        reqType: "resume",
        limit: 15,
        type: type, //1-开启 2-关闭 不传查询全部
      });
    },
    getmsg(url, msg) {
      this.$refs.loades.loadhttpes(url, msg).then((res) => {
        console.log(res);
        this.listmsg = res.arraymsg;
        this.listmsg2 = this.listmsg;
        this.openlength = res.res.data.count1;
      });
    },
  },
};
</script>
<style scoped>
.fenxiang {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.48rem;

  background-color: #ffffff;
  border-top-color: #dfdfdf;
  border-top-style: solid;
  border-top-width: 1px;
}
.fenxiang button {
  background: linear-gradient(90deg, #ff4800 0%, #fc9e46 100%);

  border: none;
  padding: 0.24rem 0;
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fenxiang img {
  width: 0.3rem;
  margin-right: 0.12rem;
}
.boclass2 > div {
  margin-left: 45%;
  transform: translateX(-50%);
}
.zpclass {
  text-align: center;
  font-size: 0.28rem;
  line-height: 0.4rem;
  color: #303133;
  margin-top: 1rem;
  margin-bottom: 0.68rem;
  font-weight: 500;
}
.boclass {
  font-size: 0.28rem;
  line-height: 0.4rem;
  color: #909399;
  font-weight: 500;
  margin: 0 0.66rem;
}
.boclass3 {
  font-size: 0.28rem;
  line-height: 0.4rem;
  color: #909399;
  font-weight: 500;
}
.boclass2 {
  text-align: left;
  white-space: nowrap;
}
.nomsgclassimg {
  width: 4.92rem;
}
.nomsgclassimg2 {
  width: 3.92rem;
}
.thetemclass {
  text-align: center;
  margin-top: 1rem;
}
.menu2 {
  font-weight: 500;
  font-size: 0.2rem;
  margin-left: 0.2rem;
  background-color: #088ffa;
  padding: 0.03rem 0.2rem;
  color: white;
  border-radius: 0.05rem;
  line-height: 0.3rem;
}
.tiaojian {
  position: absolute;
  width: 0.3rem;
  height: 0.3rem;
  text-align: center;
  line-height: 0.3rem;
  border-radius: 50%;
  background-color: #ff5f19;
  color: #ffffff;
  font-size: 0.2rem;
  top: -0.1rem;
  right: -0.1rem;
}
.nomsgclass {
  height: 1.5rem;
}
.allmsgclass {
  min-height: 100%;
}
.dyc {
  padding: 0.28rem 0;
  margin: 0 0.4rem;
  border-bottom-color: #d6d6d6;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  margin-bottom: 0.33rem;
}
.dyc > :first-child {
  font-size: 0.28rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #676767;
  line-height: 0.48rem;
  margin-bottom: 0.15rem;
}
.dyc > :nth-child(2) {
  font-size: 0.34rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #232323;
  line-height: 0.48rem;
  display: flex;
  align-items: center;
}
.dyc > :nth-child(3) {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dyc > :nth-child(4) {
  font-size: 0.28rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #232323;
  line-height: 0.48rem;
}
.dyc > :nth-child(5) {
  font-size: 0.28rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #676767;
  line-height: 0.48rem;
  margin-top: 0.21rem;
}
.sh2 {
  padding: 0.13rem 0.32rem;
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 0.42rem;
  background: #eeeeee;
  border-radius: 0.1rem;
  position: relative;
}
.sh2 img {
  position: absolute;
  width: 0.12rem;
  bottom: 0.11rem;
  right: 0.13rem;
}
.sem {
  color: #222222;
  font-weight: bold;
}
.sh1 {
  display: flex;
  align-items: center;

  font-size: 0.32rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #b6b6b6;
  line-height: 0.48rem;
}
.sh1 > div {
  margin-right: 0.54rem;
}
.daohang {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.27rem 0.35rem;
  background-color: white;
}
.addding {
  display: flex;
  align-items: center;
}
.addding > :nth-child(2) {
  font-size: 0.28rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 0.48rem;
  margin-left: 0.12rem;
}
</style>